var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-content" }, [
    _c("p", { staticClass: "labelInputText" }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
      _vm.required
        ? _c("i", { staticClass: "requiredItem" }, [_vm._v("*")])
        : _vm._e(),
    ]),
    _c("input", {
      ref: "input",
      staticClass: "inputStyle",
      attrs: { type: "text", id: "input", placeholder: _vm.placeholder },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _c("div", { staticClass: "icon-content" }, [
      _vm.icon && _vm.isLoading === false
        ? _c("i", { class: _vm.icon })
        : _vm._e(),
      _vm.isLoading ? _c("i", { staticClass: "onpoint-trash" }) : _vm._e(),
    ]),
    _vm.isLoading || _vm.infoData[0] || (_vm.value && _vm.value.length >= 3)
      ? _c(
          "div",
          { staticClass: "list-items" },
          [
            _c("Card", {
              staticClass: "item-searched",
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          [
                            _vm.isLoading
                              ? _c(
                                  "div",
                                  { staticClass: "isLoading" },
                                  [
                                    _c("ProgressSpinner", {
                                      staticClass: "load-animation",
                                      staticStyle: { stroke: "blue" },
                                    }),
                                    _c("p", { staticClass: "text-loading" }, [
                                      _vm._v(
                                        "\n                Buscando...                 \n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.infoData[0] ? [_vm._t("infoData")] : _vm._e(),
                            !_vm.infoData[0] &&
                            _vm.value.length >= 3 &&
                            !_vm.isLoading
                              ? _c("div", { staticClass: "nothing-found" }, [
                                  _c("p", { staticClass: "text" }, [
                                    _vm._v(
                                      "\n                Busca não encontrada\n            "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "subtext" }, [
                                    _vm._v(
                                      "\n              Não encontramos o CNPJ OU Razão social buscado.\n            "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }