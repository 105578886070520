<template>
  <div class="input-content">
    <p class="labelInputText">
      {{ label }}
      <i v-if="required" class="requiredItem">*</i>
    </p>
    <input
      type="text"
      class="inputStyle"
      ref="input"
      id="input"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <div class="icon-content">
      <!-- <i v-if="canClean" @click="clearAll()" class="onpoint-x-circle mr-1"></i> -->
      <i v-if="icon && isLoading===false" :class="icon"></i>      
      <i v-if="isLoading" class="onpoint-trash"></i>
    </div>
    <div v-if="isLoading || infoData[0] || (value && value.length>=3)" class="list-items">
      <Card class="item-searched">
        <template #content>
          <div>
            <div v-if="isLoading" class="isLoading">  
              <ProgressSpinner class="load-animation" style="stroke:blue;"/>
              <p class="text-loading">
                  Buscando...                 
              </p>
            </div>
            <template v-if="infoData[0]">
              <slot name="infoData" class="info-data-content"></slot>
            </template>
            <div 
              v-if="  !infoData[0]  && value.length>=3 && !isLoading" 
              class="nothing-found"
            >                
              <p class="text">
                  Busca não encontrada
              </p>
              <p class="subtext">
                Não encontramos o CNPJ OU Razão social buscado.
              </p>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>


<script>
import Card from "primevue/card";
import ProgressSpinner from 'primevue/progressspinner';
export default {
  props: {
    required: Boolean,
    label: String,
    icon: String,
    placeholder: String,
    infoData: Array,
    value: {
      type: String,
      default: "",
    },
    isLoading:{
      type: Boolean,
      default:false,
    },   
    width:String
  },
  components: {
    Card,
    ProgressSpinner
  },
  watch:{
   
  },
  data() {
    return {
      screenWidth: window.innerWidth,              
    };
  }, 
  methods: {
    cssStyleConfigs() {  
      
      this.screenWidth = window.innerWidth;     

      if(this.width){
        document.documentElement.style.setProperty("--widthInput",  this.width)
      }
      else{
        const widthInput = window.document.getElementById("input").offsetWidth+'px'
        document.documentElement.style.setProperty("--widthInput", widthInput); 
      } 

      const positionIcon = window.document.getElementById("input").offsetWidth - 25 + "px";
      
      document.documentElement.style.setProperty("--iconPosition", positionIcon);
            
    },    
  },
  mounted() {
    this.cssStyleConfigs();
    window.addEventListener("resize", this.cssStyleConfigs);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.cssStyleConfigs);
  },
};
</script>

<style lang="scss" scoped>
:root {
  --iconPosition: 0px;
  --widthInput:0px;
}
.input-content {
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
}
.inputStyle {
  margin-top: 9px;
  border-radius: 1px;  
  height: 38px;
  border: solid 1px rgba(229, 231, 235, 1);
  border-radius: 12px;
  padding-left: 10px;
  max-width: var(--widthInput)
}
.labelInputText {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.requiredItem {
  color: rgba(180, 23, 58, 1);
}
.icon-content {
  position: absolute;
  top: 35px;
  left: var(--iconPosition);
  color: rgba(75, 85, 99, 1);
}
.divider {
  margin-block: 50px;
}
.list-items{
  z-index: 1000;
  position: absolute;
  top: 70px;
  width: var(--widthInput); 
  .p-card{
     border-radius: 8px;
  }
}
.isLoading{
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  .text-loading{
    text-align: center;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  } 
}
.item-searched{
  :deep(.p-card-content){
      padding: 0px;
    }
}
.nothing-found{
  text-align: center;
  .text{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  .subtext{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

</style>